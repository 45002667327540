import Axios from 'axios';

/**
 * Go to login
 */
// const goToLogin = () => {
//   Cookies.remove(COOKIE_TOKEN, { domain: process.env.REACT_APP_DOMAIN_AKAAT });
//   Cookies.remove(COOKIE_REFRESH_TOKEN, {
//     domain: process.env.REACT_APP_DOMAIN_AKAAT,
//   });

//   window.location.href = `${
//     process.env.REACT_APP_URL_AUTH
//   }/login?nextUrl=${encodeURIComponent(window.location.href)}`;
// };

/**
 * Axios create
 */
const Http = Axios.create({
  // baseURL: process.env.REACT_APP_API_ENDPOINT,
  baseURL: 'http://localhost:1337',
  // headers: { Authorization: `Bearer ${token}` },
  headers: {
    'Cache-Control': 'no-store',
    'Content-Security-Policy':
      "default-src 'self'; upgrade-insecure-requests; block-all-mixed-content; connect-src 'self'; img-src 'self'; frame-ancestors 'self'; form-action 'self'; font-src 'self'; style-src 'self'; script-src 'strict-dynamic'; script-src-elem 'self'; report-to default; base-uri 'self';",
    'Permissions-Policy': 'geolocation=self',
    'Referrer-Policy': 'no-referrer-when-downgrade',
    'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload',
    'X-Content-Type-Options': 'nosniff',
    'X-Frame-Options': 'SAMEORIGIN',
    'X-XSS-Protection': '1; mode=block',
    'Cross-Origin-Embedder-Policy': 'require-corp',
    'Cross-Origin-Opener-Policy': 'same-origin',
    'Cross-Origin-Resource-Policy': 'same-origin',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Origin': 'https://akaat.com'
  }
});

/**
 * Request interceptor
 */
Http.interceptors.request.use(
  request => {
    // request.headers[X_AKAAT_REQUEST_RESOURCE] =
    //   process.env.REACT_APP_X_AKAAT_REQUEST_RESOURCE;
    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

/**
 * Response intorceptor
 */
// Http.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     const status = error.response?.status;

//     // // Get refresh token
//     // if (status === 401 && rememberMe) {
//     //   (async () => {
//     //     try {
//     //       const bodyData = { accessToken: token, refreshToken };
//     //       const url = `${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.REFRESH_TOKEN}`;
//     //       const res = await Axios.post(url, bodyData);

//     //       if (!res?.data?.data?.accessToken) goToLogin();

//     //       Cookies.set(COOKIE_TOKEN, res.data.data.accessToken, {
//     //         expires: 365,
//     //         domain: process.env.REACT_APP_DOMAIN_AKAAT,
//     //         secure: process.env.NODE_ENV === "production",
//     //       });

//     //       window.location.reload();
//     //     } catch (_) {
//     //       goToLogin();
//     //     }
//     //   })();
//     // }

//     // // Clear token in cookie, redirect to login page
//     // else if (status === 401 && !rememberMe) {
//     //   Modal.error({
//     //     title: getObjectByValue(status, HTTP_STATUS_CODES)?.label,
//     //     content: i18next.t("akaat:common.sessionTimeout"),
//     //     autoFocusButton: "ok",
//     //     zIndex: 9999,
//     //     okText: i18next.t("akaat:common.ok"),
//     //     onOk: () => goToLogin(),
//     //   });
//     // }

//     // // Push error notification
//     // else {
//     //   notification.error({
//     //     message: HTTP_STATUS_CODES.some((item) => item.value === status)
//     //       ? getObjectByValue(status, HTTP_STATUS_CODES)?.label
//     //       : i18next.t("akaat:common.error"),
//     //     description: error.response?.data?.message || "",
//     //   });
//     // }

//     return Promise.reject(error.response ? error.response : error);
//   }
// );

export { Http as aloApi };
