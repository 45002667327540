import { DeviceFarmImageCloudDevices } from '../../../../assets/images';

import './device-farm-cloud-devices.scss';

const DeviceFarmCloudDevices = () => {
  return (
    <section className="device-farm-cloud-devices">
      <div className="w-1440">
        <div className="box-title">
          <h2 className="title-cloud-devices">Cloud Devices</h2>
          <div className="sub-title">
            Cloud devices can be integrated with akaAT Studio to execute automation tests. Below is a list of compatible
            tools. These tools improve automation testing by offering scalable, flexible, and cost-effective testing
            environments.
          </div>
        </div>

        <div className="img-cloud-devices">
          <img src={DeviceFarmImageCloudDevices} alt="Cloud Devices" />
        </div>
      </div>
    </section>
  );
};

export default DeviceFarmCloudDevices;
