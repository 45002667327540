import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

/**
 * Scroll into hash
 */

export const scrollToLocationHash = locationHash => {
  if (!locationHash) {
    return;
  }

  const elementId = locationHash.split('#')[1];
  const element = document.getElementById(elementId);

  if (!element) {
    return;
  }

  element.scrollIntoView();
};

const ScrollToTop = props => {
  const location = useLocation();
  useEffect(() => {
    if (location?.hash) {
      // scrollToLocationHash(location.hash);
    } else {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }, [location]);

  return <>{props.children}</>;
};

export default ScrollToTop;
