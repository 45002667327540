import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useViewportSize } from '../../common/hooks';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import Layout from '../../common/components/layout';
import DeviceFarmBanner from './components/device-farm-banner/device-farm-banner';
import DeviceFarmBenefit from './components/device-farm-benefit/device-farm-benefit';
import DeviceFarmFeature from './components/device-farm-feature/device-farm-feature';
import DeviceFarmIntroduction from './components/device-farm-introduction/device-farm-introduction';
import DeviceFarmCloudDevices from './components/device-farm-cloud-devices/device-farm-cloud-devices';
import DeviceFarmEnd from './components/device-farm-end/device-farm-end';
import MobileDeviceFarmBanner from '../../mobile-pages/aka-device-farm/components/device-farm-banner/device-farm-banner';
import MobileDeviceFarmBenefit from '../../mobile-pages/aka-device-farm/components/device-farm-benefit/device-farm-benefit';
import MobileDeviceFarmFeature from '../../mobile-pages/aka-device-farm/components/device-farm-feature/device-farm-feature';

import './aka-device-farm.scss';

const AkaDeviceFarmPage = () => {
  const { formatMessage } = useIntl();
  const [viewportWidth] = useViewportSize();
  // tracking data appInsights
  const appInsights = useAppInsightsContext();
  const metricData = {
    average: 10,
    name: 'React Component Engaged Time (seconds)',
    sampleCount: 1
  };
  const additionalProperties = { 'Component Name': 'AkaDeviceFarmPage' };
  appInsights.trackMetric(metricData, additionalProperties);
  // end tracking data appInsights

  return (
    <Layout hiddenStarted product>
      <div className="device-farm-page">
        <Helmet>
          <title>{formatMessage({ id: 'seo.device_farm.title_tag' })}</title>
          <meta name="description" content={formatMessage({ id: 'seo.device_farm.meta_description' })} />
        </Helmet>
        {viewportWidth > 991 ? <DeviceFarmBanner /> : <MobileDeviceFarmBanner />}

        <DeviceFarmIntroduction />
        {viewportWidth > 991 ? (
          <>
            <DeviceFarmBenefit />
            <DeviceFarmFeature />
          </>
        ) : (
          <>
            <MobileDeviceFarmBenefit />
            <MobileDeviceFarmFeature />
          </>
        )}
        <DeviceFarmCloudDevices />
        <DeviceFarmEnd />
      </div>
    </Layout>
  );
};

export default AkaDeviceFarmPage;
