import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import Layout from '../../common/components/layout';
import EventsTab from './event-tab';
import './events-webinars.scss';
import EventsPage from './events/event';
import WebinarsPage from './webinars/webinars';

const EventsWebinarsPage = () => {
  const { formatMessage } = useIntl();
  const [currentTab, setCurrentTab] = useState('Events');
  const [textSearch, setTextSearch] = useState('');
  const [searchResults, setSearchResults] = useState(false);

  // tracking data appInsights
  const appInsights = useAppInsightsContext();
  const metricData = {
    average: 10,
    name: 'React Component Engaged Time (seconds)',
    sampleCount: 1
  };
  const additionalProperties = { 'Component Name': 'EventsPage' };
  appInsights.trackMetric(metricData, additionalProperties);
  // end tracking data appInsights

  const handelTab = name => {};
  const renderTab = () => {
    switch (currentTab) {
      case 'Webinars':
        return <h1 className="divider-48">Webinars</h1>;
      default:
        return <h1 className="divider-48">Events</h1>;
    }
  };
  return (
    // subscribe
    <Layout hiddenStarted subscribe>
      <div className="events-webinars-page">
        <Helmet>
          <title>{formatMessage({ id: 'seo.event.title_tag' })}</title>
          <meta name="description" content={formatMessage({ id: 'seo.event.meta_description' })} />
        </Helmet>
        {renderTab()}
        <EventsTab
          handelTab={handelTab}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          setTextSearch={setTextSearch}
          textSearch={textSearch}
          setSearchResults={setSearchResults}
        />
        {currentTab === 'Events' && (
          <EventsPage currentTab={currentTab} textSearch={textSearch} searchResults={searchResults} />
        )}
        {currentTab === 'Webinars' && (
          <WebinarsPage currentTab={currentTab} textSearch={textSearch} searchResults={searchResults} />
        )}
      </div>
    </Layout>
  );
};

export default EventsWebinarsPage;
