import React, { useEffect, useRef, useState } from 'react';
import ScrollSpy from 'react-ui-scrollspy';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';

import Layout from '../../common/components/layout';
import TermTab from './components/term-tab';

import './terms.scss';
import TermsOfUse from './components/terms-of-use';
import TermsPrivacyPolicy from './components/term-privacy-policy';

const TermPage = () => {
  const { formatMessage } = useIntl();
  const [currentTab, setCurrentTab] = useState('rules1');

  // const handelTab = (e) => {
  //   e.preventDefault();
  //   const target = window.document.getElementById(
  //     e.currentTarget.href.split("#")[1]
  //   );

  //   if (target) {
  //     var headerOffset = 80;
  //     var elementPosition = target.getBoundingClientRect().top;
  //     var offsetPosition = elementPosition - headerOffset;

  //     window.scrollBy({
  //       top: offsetPosition,
  //       behavior: "smooth",
  //     });
  //   }
  // };
  // const handleScroll = () => {
  //   const position = window.pageYOffset;
  //   setScrollPosition(position);
  // };
  const handleOnScroll = e => {
    // e.preventDefault();
    // const target = window.document.getElementById(
    //   e.currentTarget.href.split("#")[1]
    // );
    // if (target) {
    //   var headerOffset = 80;
    //   var elementPosition = target.getBoundingClientRect().top;
    //   var offsetPosition = elementPosition - headerOffset;
    //   window.scrollBy({
    //     top: 80,
    //     behavior: "smooth",
    //   });
    // }
    // window.scrollBy({
    //   top: 180,
    //   behavior: "smooth",
    // });
  };

  // useEffect(() => {
  //   window.addEventListener("onClick", handleOnScroll);

  //   return () => {
  //     window.removeEventListener("onClick", handleOnScroll);
  //   };
  // }, []);

  return (
    <Layout hiddenStarted>
      <div className="term-page" onScroll={handleOnScroll}>
        <Helmet>
          <title>{formatMessage({ id: 'seo.term_of_use.title_tag' })}</title>
          <meta name="description" content={formatMessage({ id: 'seo.term_of_use.meta_description' })} />
        </Helmet>
        <h1 className="term-header">AKAAT Terms </h1>
        <div className="term-description" onScroll={handleOnScroll}>
          <section className="blog-article-content divider-60">
            <TermTab currentTab={currentTab} setCurrentTab={setCurrentTab} />
            <div className="term-left">
              <TermsOfUse currentTab={currentTab} />
              <TermsPrivacyPolicy currentTab={currentTab} />
              {currentTab === 'rules21' && (
                <div id="rules21">
                  <ul>
                    <li>
                      Katalon:
                      <a
                        href="https://katalon.com/terms"
                        target="_blank"
                        rel="noreferrer"
                      >{`https://katalon.com/terms`}</a>
                    </li>
                    <li>
                      Azure:
                      <a
                        href="https://docs.microsoft.com/en-us/azure/active-directory/conditional-access/terms-of-use"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {' '}
                        {`https://docs.microsoft.com/en-us/azure/active-directory/conditional-access/terms-of-use`}
                      </a>
                    </li>
                    <li>
                      Apple:
                      <a
                        href="https://www.apple.com/legal/internet-services/terms/site.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {' '}
                        {`https://www.apple.com/legal/internet-services/terms/site.html`}
                      </a>
                    </li>
                    <li>
                      Slack:
                      <a
                        href="https://slack.com/trust/privacy/privacy-policy#retention"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {' '}
                        {`https://slack.com/trust/privacy/privacy-policy#retention`}
                      </a>
                    </li>
                    <li>
                      TeachTarget:
                      <a
                        href="https://www.techtarget.com/searchdatabackup/definition/data-retention-policy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {' '}
                        {`https://www.techtarget.com/searchdatabackup/definition/data-retention-policy`}
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default TermPage;
